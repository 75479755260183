import React from "react";
import { Link } from "gatsby";

import PageLayout from "../components/page-layout";

import * as style from "./404.module.css";

export default function DiversityPage({ data }) {
  return (
    <PageLayout mainClassName={style.main}>
      <h1>Something went wrong</h1>
      <p>We couldn't find what you were looking for</p>
      <Link to="/" style={{ marginBottom: "100px" }}>
        Back to main page
      </Link>
    </PageLayout>
  );
}
